<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="checkFactorReceipt"
      :show-new="false"
      :show-print="!checkFactorReceipt"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <receipt-entry-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <!-- <div class="tile is-parent animated lightSpeedIn" v-show="toggle"> -->
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(receiptentry, index) in receiptEntrySummaries"
                :key="receiptentry.invoiceID"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td v-if="checkFactorReceipt"
                  class="has-vertical-middle has-text-centered">
                  <input class="is-checkradio is-info"
                    :class="{ 'has-background-color': receiptentry.isChecked }"
                    type="checkbox"
                    :disabled="receiptentry.balance==0 ? true : false"
                    :id="`factor${index}`"
                    :name="`factor${index}`"
                    @click="selectFactorInv(receiptentry)"
                    v-model="receiptentry.isChecked">
                  <label :for="`factor${index}`"
                    data-label-empty />
                </td>
                <td>
                  <a :class="{ 'disable-click': showFactorInv}"
                    @click.prevent="edit(receiptentry.assetID,$event)"
                    :href="generateUrl(receiptentry.assetID)">{{ receiptentry.invoiceRef }}</a>
                </td>
                <td>{{ receiptentry.customerName }}</td>
                <td>{{ $filters.formatDateTimezone(receiptentry.invoiceDate, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ receiptentry.balance | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">
                  <span>{{ receiptentry.totalExGst | formatNumber($userInfo.locale) }}</span>
                </td>
                <td class="has-text-right">
                  <span>{{ receiptentry.gst | formatNumber($userInfo.locale) }}</span>
                </td>
                <td class="has-text-right">
                  <span>{{ receiptentry.totalIncGst | formatNumber($userInfo.locale) }}</span>
                </td>
                <td class="has-text-left">
                  <span>{{ receiptentry.quoteNo }}</span>
                </td>
                <td v-if="showAction"
                  class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="edit(receiptentry.assetID, $event)"
                    :href="generateUrl(receiptentry.assetID)"
                    data-tooltip="Receipt Payment">
                    <span class="icon is-medium">
                      <i class="mdi mdi-currency-usd mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <vue-fab v-if="isFactor || !showFactorInv"
      position="right-bottom"
      :z-index="30"
      :actions="fabActions"
      main-tooltip="Receipt Actions"
      @showFactorReceipt="filterFactorRec()"
      @advanceReceipt="redirectToAdvReceipt()"
      @factorReceipt="redirectToFactor()"
      @bankundepositedfund="redirectToBankUndepositedFund($userInfo.companyId)" />
    <advance-receipt-modal v-if="isLoadAdvPaymentModalActive"
      :active.sync="isLoadAdvPaymentModalActive"
      :show-icon="false"
      @close="closeModal()">
      <p slot="text-title">Advance Receipt Entry</p>
    </advance-receipt-modal>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="confirmModalCancel(true)"
      @cancel="confirmModalCancel(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Factor Invoice</p>
      <p slot="text-content">
        You have selected
        <span class="has-text-primary has-text-weight-bold">{{ `${selectedFactorReceipts.length}` }}</span> unfactored invoices. Continue to go back?
        <!-- <span class="tag is-danger">In-Active</span> anyway? -->
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import ReceiptEntryListSideMenu from './ReceiptEntryListSideMenu'
import ReceiptEntryService from './ReceiptEntryService'
import Guid from '@/components/Guid'
import { EventHubTypes, AppHeaderButtonTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import StoreMixin from './storeMixin'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { Emailer } from '@/classes'
import { VueFab } from '@/components/VueFab'
import { TableColumn } from '@/classes'
import _debounce from 'lodash.debounce'
import _cloneDeep from 'lodash/cloneDeep'
import StoreUtil from '@/store/utils'
import store from '@/store'
//import FactorInvoiceRoutes from '../factorinvoice/route-types'
import ReceiptPaymentRoutes from '../receiptpayment/route-types'
import BankUndepositedFundRoutes from '../bankundepositedfund/route-types'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import AdvanceReceiptModal from '../advancereceipt/AdvanceReceiptModal'
import { ConfirmModal } from '@/components/BulmaModal'

export default {
  name: 'ReceiptEntryListView',
  components: {
    AppDetailHeader,
    ReceiptEntryListSideMenu,
    BulmaTable,
    VueFab,
    AdvanceReceiptModal,
    ConfirmModal
  },
  mixins: [DateTimeFiltersMixin, StoreMixin, NumberFiltersMixin],
  data() {
    return {
      totalRows: 0,
      receiptEntrySummaries: {},
      selectedFactorReceipts: [],
      accessRoutes: [],
      filter: {
        invoiceNo: '',
        debtor: '',
        quoteNo: '',
        filterType: 1,
        includeAdvance: 0,
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 16
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false,
      fabActions: [],
      checkFactorReceipt: false,
      isFactor: false,
      showFactorInv: false,
      columns: _cloneDeep(Columns),
      showAction: true,
      isLoadAdvPaymentModalActive: false,
      isConfirmModalActive: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    }
  },
  watch: {
    selectedFactorReceipts: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          this.$forceUpdate()
        }
      }, 500),
      deep: true
    },
    columns(newVal, oldVal) {
      if (this.checkFactorReceipt == false && this.columns.filter((i) => i.name == 'isChecked').length > 0) {
        this.columns.splice(-1, 1)
        this.showAction = false
        this.checkFactorReceipt = true
      }
    },
    selectedFactorReceipts(newVal, oldVal) {
      if (this.selectedFactorReceipts.length == 0) {
        this.isFactor = false
        this.fabActions = this.fabActions.filter((i) => i.name !== 'factorReceipt')
      }
    }
  },
  created() {
    this.$route.meta.title = ''
    this.setupFabButtons()
    this.getData()
    this.$eventHub.$on(EventHubTypes.ToggleSideMenu, (data) => {
      this.toggle = false
      setTimeout(() => {
        this.toggle = true
      }, 200)
    })
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
      }
    },
    selectFactorInv(receiptentry) {
      this.isFactor = true

      if (receiptentry.isChecked === false) {
        this.selectedFactorReceipts.push(receiptentry)
      } else if (receiptentry.isChecked === true) {
        let index = this.selectedFactorReceipts
          .map(function (id) {
            return id.invoiceID
          })
          .indexOf(receiptentry.invoiceID)
        if (index >= 0) {
          this.selectedFactorReceipts.splice(index, 1)
        }
        // this.selectedFactorReceipts.splice(this.selectedFactorReceipts.indexOf(receiptentry),1)
      }
      // this.setupFabButtons()
      if (this.isFactor) {
        this.fabActions.splice(0, 1, {
          name: 'factorReceipt',
          icon: 'alpha-f',
          color: '#418e8e',
          tooltip: 'Factor Selected Invoices'
        })
      }
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    setupFabButtons() {
      if (!this.isFactor && this.fabActions.filter((i) => i.name == 'showFactorReceipt').length == 0) {
        this.fabActions.splice(this.fabActions.length, 1, {
          name: 'showFactorReceipt',
          icon: 'alpha-f',
          color: '#a8a145',
          tooltip: 'Factor Invoices'
        })
        this.fabActions.splice(this.fabActions.length, 1, {
          name: 'advanceReceipt',
          icon: 'currency-usd',
          color: '#c14747',
          tooltip: 'Advance Receipt Entry'
        })
        this.fabActions.splice(this.fabActions.length, 1, {
          name: 'bankundepositedfund',
          icon: 'bank-plus',
          color: '#418e8e',
          tooltip: 'Bank Un-Deposited Funds'
        })
      }
    },
    filterFactorRec() {
      this.$notification.openNotificationWithType('success', 'Factor Invoice', 'Please select invoices to factor')
      this.fabActions.splice(0, 3)
      if (this.checkFactorReceipt == false) {
        this.columns.unshift(new TableColumn('Select', 'isChecked', false, 'asc', 'centered'))
      }
      this.$route.meta.title = 'Factor Invoice'
      this.retrieveFilter()
      this.filter.includeAdvance = 1
      this.showFactorInv = true
      this.persistFilter()
      this.getData()
    },
    async getData() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.receiptEntrySummaries = await ReceiptEntryService.getEntitySummaries(this.filter)
      this.totalRows = this.receiptEntrySummaries.length > 0 ? this.receiptEntrySummaries[0].totalRows : 0
      if (this.selectedFactorReceipts.length > 0) {
        this.selectedFactorReceipts.forEach((i) => {
          let index = this.receiptEntrySummaries.map((i) => i.invoiceID).indexOf(i.invoiceID)
          if (index >= 0) {
            this.receiptEntrySummaries[index].isChecked = true
          }
        })
      }
      this.isTableLoading = false
      this.$hideSpinner()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    generateUrl(id) {
      return `receiptpayment/${id}`
    },
    async redirectToFactor() {
      let id = Guid.newGuid()
      await StoreUtil.setStorage(id, 'receiptentry', _cloneDeep(this.selectedFactorReceipts))
      await store.dispatch('factorinvoice/addStoreItem', id)
      this.$router.push({
        //name: FactorInvoiceRoutes.FactorInvoiceDetail.name,
        //params: { id: id }
        path: `receiptentry/factorinvoices/${id}`
      })
    },
    async redirectToAdvReceipt() {
      await store.dispatch('advancereceipt/addStoreItem')
      this.$nextTick(() => {
        this.isLoadAdvPaymentModalActive = true
      })
    },
    redirectToBankUndepositedFund(id) {
      console.log('BankUndepositedFund Route')
      this.$router.push({
        name: BankUndepositedFundRoutes.BankUndepositedFundDetail.name,
        // name: 'ReceiptEntryEnterReceiptDetail',
        params: { CompanyId: id }
      })
    },
    edit(id, event) {
      this.$router.push({
        name: ReceiptPaymentRoutes.ReceiptPaymentDetail.name,
        // name: 'ReceiptEntryEnterReceiptDetail',
        params: { AssetId: id }
      })
    },
    print1() {
      let zeroBalanceCheck = 0
      let sixMonthCheck = 0
      let allInvoiceCheck = 0

      if (this.filter.filterType == 1) {
        zeroBalanceCheck = 1
      } else if (this.filter.filterType == 2) {
        sixMonthCheck = 1
      } else {
        allInvoiceCheck = 1
      }

      const params = {
        CompanyID: this.$userInfo.companyId,
        InvoiceNo: this.filter.invoiceNo === '' ? '' : this.filter.invoiceNo,
        Debtor: this.filter.debtor === '' ? '' : this.filter.debtor,
        QuoteNo: this.filter.quoteNo === '' ? '' : this.filter.quoteNo,
        ZeroBalance: zeroBalanceCheck,
        SixMonts: sixMonthCheck,
        AllInvoice: allInvoiceCheck,
        SortBy: this.filter.sortColumn.toUpperCase() + ' ' + this.filter.sortOrder.toUpperCase()
      }

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Receipt Entry Report'
      emailer.reportName = 'rptReceiptEntryList'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    cancel() {
      if (this.selectedFactorReceipts.length > 0) {
        this.isConfirmModalActive = true
      } else if ((this.selectedFactorReceipts = [])) {
        this.resetList()
      }
    },
    resetList() {
      if (this.showAction == false && this.columns.filter((i) => i.name == 'isChecked').length > 0) {
        this.columns = _cloneDeep(Columns)
      }
      this.selectedFactorReceipts = []
      this.showAction = true
      this.isFactor = false
      this.showFactorInv = false
      this.checkFactorReceipt = false
      this.$route.meta.title = ''
      this.filter.invoiceNo = ''
      this.filter.debtor = ''
      this.filter.quoteNo = ''
      this.filter.filterType = 1
      this.filter.includeAdvance = 0
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
      this.filter.pageIndex = 1
      this.filter.pageSize = 16
      this.setupFabButtons()
      this.persistFilter()
      this.getData()
    },
    closeModal() {
      this.isLoadAdvPaymentModalActive = false
    },
    confirmModalCancel(confirmCancel) {
      this.isConfirmModalActive = false
      if (confirmCancel) {
        this.resetList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zoomIn {
  animation: zoomIn 0.5s;
}
.disable-click {
  pointer-events: none;
}
</style>
